import { getQueryParams } from 'app/utils/queryParams';
import { CurrencyOption, WithCurrencyInfo } from 'app/utils/types';
import React, {
  createContext, useContext, useMemo, useState,
} from 'react';

interface CurrencyContextState {
  currency: CurrencyOption
  currencyPrefix: string,
  defaultSimulationCurrency: CurrencyOption | null
  ufRatio: number
  handleToggleCurrency: () => void
  handleInitCurrencyData: (initCurrency: CurrencyOption, initUfRatio: number) => void
}

export const CurrencyContext = createContext<CurrencyContextState | undefined>(undefined);

interface CurrencyContextProviderProps {
  children: JSX.Element
}

const DEFAULT_CURRENCY: CurrencyOption = 'clp';
const DEFAULT_UF_RATIO = 36_554.14; // 2023-11-28

export function CurrencyContextProvider({ children }: CurrencyContextProviderProps) {
  const queryParams = getQueryParams<WithCurrencyInfo>();

  // The `defaultSimulationCurrency` keeps track of the default currency type for the given
  // simulation based on the API response.
  const [
    defaultSimulationCurrency,
    setDefaultSimulationCurrency,
  ] = useState<CurrencyOption | null>(null);

  // Meanwhile, `currency` stores the current state of the selected cuurency type
  const [currency, setCurrency] = useState<CurrencyOption>(DEFAULT_CURRENCY);
  const [ufRatio, setUfRatio] = useState<number>(DEFAULT_UF_RATIO);

  const handleInitCurrencyData = (initCurrency: CurrencyOption, initUfRatio: number) => {
    setDefaultSimulationCurrency(initCurrency);

    // User can FORCE currency using query parameters; so API currency option value will be
    // overwritten by the user currency selection
    setCurrency(queryParams.currency ?? initCurrency);

    setUfRatio(initUfRatio);
  };

  const handleToggleCurrency = () => (currency === 'clp' ? setCurrency('clf') : setCurrency('clp'));

  const currencyPrefix = currency === 'clp' ? '$' : 'UF';

  const contextValues: CurrencyContextState = useMemo(() => ({
    currency,
    currencyPrefix,
    defaultSimulationCurrency,
    ufRatio,
    handleToggleCurrency,
    handleInitCurrencyData,
  }), [
    currency,
    defaultSimulationCurrency,
    ufRatio,
    handleToggleCurrency,
    handleInitCurrencyData,
    currencyPrefix,
  ]);

  return (
    <CurrencyContext.Provider value={contextValues}>
      {children}
    </CurrencyContext.Provider>
  );
}

export function useCurrencyContext() {
  const contextValues = useContext(CurrencyContext);

  if (!contextValues) {
    throw new Error('Cannot access to CurrencyContext. Make sure the provider is available for this component');
  }

  return contextValues;
}
