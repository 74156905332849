/* eslint-disable jsx-a11y/label-has-associated-control */
import { useCurrencyContext } from 'app/contexts/CurrencyContext';
import React, { ChangeEvent, useEffect, useState } from 'react';

export function CurrencySwitch() {
  const [isChecked, setIsChecked] = useState(false);
  const [label, setLabel] = useState('');
  const { handleToggleCurrency, currency } = useCurrencyContext();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    handleToggleCurrency();
    setIsChecked(e.target.checked);
  };

  useEffect(() => {
    if (currency === 'clp') {
      setIsChecked(true);
    }
  }, [currency]);

  useEffect(() => {
    if (currency === 'clp') {
      setLabel('Cambiar a UF');
      return;
    }

    setLabel('Cambiar a pesos');
  }, [currency]);

  return (
    <div className="d-flex justify-content-end mb-3">
      <div className="form-check form-switch">
        <input onChange={handleChange} checked={isChecked} className="form-check-input" type="checkbox" role="switch" id="currencySwitch" />
        <label className="form-check-label" htmlFor="currencySwitch">{label}</label>
      </div>
    </div>
  );
}
