import { useEffect, useState } from 'react';
import { useCurrencyContext } from 'app/contexts/CurrencyContext';

import { useIsFirstRender } from './useIsFirstRender';
import { FormatCurrencyOptions, formatCurrencyMap } from '../formatters';
import { transformCurrencyMap } from '../money';
import { getQueryParams } from '../queryParams';
import { WithCurrencyInfo } from '../types';

export const withPrefix: FormatCurrencyOptions = {
  withPrefix: true,
};

/**
 *
 * Exposes a function for transform the amounts from some currency to another based on
 * the selected currency.
 *
 * This function should be used inside `CurrencyContext` tree to be allowed to capture
 * `currency` and `ufRatio` values
 */
export function useTransformCurrency(amount: number, formatOptions?: FormatCurrencyOptions) {
  const queryParams = getQueryParams<WithCurrencyInfo>();
  const isFirstRender = useIsFirstRender();
  const { currency, ufRatio, defaultSimulationCurrency } = useCurrencyContext();

  /**
   * We need to track the current value to apply transformation over the right value
   * on each function calls.
   */
  const [storedAmount, setStoredAmount] = useState(amount);
  const [formattedValue, setFormattedValue] = useState(
    formatCurrencyMap[currency](amount, formatOptions)
  );

  useEffect(() => {
    if (isFirstRender) {
      setStoredAmount(amount);

      setFormattedValue(formatCurrencyMap[currency](amount, formatOptions));
    }
  }, []);

  useEffect(() => {
    /**
     * First time the value should keep as the same because we just want to apply the transformation
     * only when `currency` changes; not on first render.
     *
     * The only way values are transformed on first render is if given query params currency is not
     * as the provided init currency of the simulation. When this happens, we need to convert and
     * overwrite values because user is FORCING the currency option through query parameters
     */
    if (isFirstRender && (!queryParams.currency || defaultSimulationCurrency === currency)) return;

    const transformedValue = transformCurrencyMap[currency](storedAmount, ufRatio);
    setStoredAmount(transformedValue);

    setFormattedValue(formatCurrencyMap[currency](transformedValue, formatOptions));
  }, [currency]);

  return formattedValue;
}
